import type {ContainerQueryCondition} from '@Hooks/useContainerQuery';

export enum EventKeys {
  ENTER_KEY = 'Enter',
}

export enum FLEX_DIRECTION {
  COLUMN = 'column',
  ROW = 'row',
}

export const TABLET_SCREEN_BREAKPOINT = 768;
export const LARGE_MOBILE_SCREEN_BREAKPOINT = 550;
export const MOBILE_SCREEN_BREAKPOINT = 480;

export const isContainerOverflowing = (container: HTMLElement): boolean => {
  return container.scrollHeight - container.clientHeight > 0;
};

export const getVisibleHeightOfElement = (element: HTMLElement, container: HTMLElement): number => {
  const {scrollTop} = container;
  const scrollBottom = scrollTop + container.clientHeight;

  const elementTopFromContainer = element.getBoundingClientRect().top - container.getBoundingClientRect().top;
  const elementTop = elementTopFromContainer + scrollTop;
  const elementBottom = elementTop + element.offsetHeight;
  const visibleTop = elementTop < scrollTop ? scrollTop : elementTop;
  const visibleBottom = elementBottom > scrollBottom ? scrollBottom : elementBottom;

  return visibleBottom - visibleTop;
};

/**
 * Calculates the max visible height of each child element inside the scrollabeContainer and returns the one with the max height.
 * @param childElements
 * @param scrollabeContainer
 */
export const getChildElementWithMaxVisibleHeight = (childElements: Array<HTMLElement>, scrollabeContainer: HTMLElement): HTMLElement => {
  let visibleElementHeight = 0;
  let maxVisibleElementHeight = 0;
  let maxVisibleElementHeightIndex = 0;

  for (let i = 0; i < childElements.length; i++) {
    // Gets the amount of pixels currently visible within the scrollabeContainer
    visibleElementHeight = getVisibleHeightOfElement(childElements[i], scrollabeContainer);

    if (visibleElementHeight > maxVisibleElementHeight) {
      maxVisibleElementHeightIndex = i;
      maxVisibleElementHeight = visibleElementHeight;
    } else if (visibleElementHeight < 0) {
      break;
    }
  }
  return childElements[maxVisibleElementHeightIndex];
};

export const isTabletScreenWidth = (): boolean => {
  return window.innerWidth <= TABLET_SCREEN_BREAKPOINT;
};

export const isLargeMobileScreenWidth = (): boolean => {
  return window.innerWidth <= LARGE_MOBILE_SCREEN_BREAKPOINT;
};

export const isMobileScreenWidth = (): boolean => {
  return window.innerWidth <= MOBILE_SCREEN_BREAKPOINT;
};

export const addClassToElement = (elementId: string, classToAdd: string): void => {
  document.getElementById(elementId)?.classList?.add(classToAdd);
};

export const removeClassFromElement = (elementId: string, classToRemove: string): void => {
  document.getElementById(elementId)?.classList?.remove(classToRemove);
};

export const isTextElement = (element: Element): boolean => {
  const tagName = element.tagName.toLowerCase();
  if (tagName === 'textarea') return true;
  if (tagName !== 'input') return false;
  let type = element.getAttribute('type');
  if (type) {
    type = type.toLowerCase();
    // if any of these input types is not supported by a browser, it will behave as input type text.
    const inputTypes = ['text', 'password', 'number', 'email', 'tel', 'url', 'search', 'date', 'datetime', 'datetime-local', 'time', 'month', 'week'];
    return inputTypes.indexOf(type) >= 0;
  }
  return false;
};

export const areChecksTrueForContainerQueryBreakpoint = (containerQueryBreakpoint: ContainerQueryCondition, domElementWidth: number, domElementHeight: number): boolean => {
  if (containerQueryBreakpoint.maxHeight && !(domElementHeight <= containerQueryBreakpoint.maxHeight)) {
    return false;
  }
  if (containerQueryBreakpoint.maxWidth && !(domElementWidth <= containerQueryBreakpoint.maxWidth)) {
    return false;
  }
  if (containerQueryBreakpoint.minHeight && !(domElementHeight >= containerQueryBreakpoint.minHeight)) {
    return false;
  }

  if (containerQueryBreakpoint.minWidth && !(domElementWidth >= containerQueryBreakpoint.minWidth)) {
    return false;
  }

  return true;
};

/**
 * checks whether an element is visible completely in the viewport only horizontally
 * @param element
 */
export const isHorizontallyInViewport = (element: HTMLElement): boolean => {
  const boundingBox = element.getBoundingClientRect();
  return (
      boundingBox.left >= 0 &&
      boundingBox.right <= (document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth)
  );
}

export const isOverflowingViewportFromTop = (element: HTMLElement): boolean => {
  return element.getBoundingClientRect().top < 0;
}

export const addClickOutsideListener = (elementQuerySelector: string, callback: () => void | Promise<void>): VoidFunction => {
  const handleClickOutside = (event: MouseEvent): void => {
    const target = event.target as HTMLElement;
    const element = document.querySelectorAll(elementQuerySelector)[0];

    if (element && !element.contains(target)) {
      void callback();
    }
  };

  document.addEventListener('click', handleClickOutside, {capture: true});

  return (): void => {
    document.removeEventListener('click', handleClickOutside, {capture: true});
  };
};
